import React, { useState, useEffect } from 'react'
import ReactMarkdown from 'react-markdown'
import './style.min.css'

const Slide = ({ data, nav=true, dots=true, autoPlay=true, autoPlayDuration=3 }) => {

  const [ index, setIndex ] = useState(0);
  const [ slidePause, setSlidePause ] = useState(false);
  const bgStyleBef = { 'backgroundImage': `url(${data[index === 0 ? data.length - 1 : index - 1].url})` };
  const bgStyle = { 'backgroundImage': `url(${data[index].url})` };
  const bgStyleAf = { 'backgroundImage': `url(${data[index === data.length - 1 ? 0 : index + 1].url})` };

  let autoPlayInterval;

  const goPrev = () => {
    const isFirst = index === 0;
    const newIndex = isFirst ? data.length - 1 : index - 1;
    changeIndex(newIndex);
  }

  const goNext = () => {
    const isLast = index === data.length - 1;
    const newIndex = isLast ? 0 : index + 1;
    changeIndex(newIndex);
  }

  const changeIndex = newIndex => {
    const container = document.querySelector('.imageSlide__active');
    container.style.opacity = 0;
    setTimeout(() => setIndex(newIndex), 500);
    container.style.opacity = 1;
  }

  const startAutoplay = () => {
    if (autoPlayInterval === undefined) {
      const time = 1000 * autoPlayDuration;
      autoPlayInterval = setInterval(() => goNext(), time)
    }
  }

  const stopAutoplay = () => {
    if (autoPlayInterval !== undefined && autoPlayInterval > 0) {
      clearInterval(autoPlayInterval)
    }
  }

  useEffect(() => {
    return () => stopAutoplay();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {

    if (autoPlay && !slidePause) {
      startAutoplay();
    }
    if (slidePause) {
      stopAutoplay();
    }

    return () => stopAutoplay()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ index, slidePause ])

  return (
    <div className="imageSlide__container">
      <span style={bgStyleBef}></span>
      <span style={bgStyleAf}></span>
      <div className={`imageSlide__active${dots ? ' dots__active' : ''}${nav ? ' arrows__active' : ''}${autoPlay ? ' autoPlay__active' : ''}`} style={bgStyle}>
          {
            !!data[index].label ? (
              <ReactMarkdown className={"imageSlide__label"}>
                { data[index].label }
              </ReactMarkdown>
            ) : ''
          }
          {
            autoPlay ? (
              <div className={`autoPlay__control ${slidePause ? 'paused' : 'active'}`} onClick={() => setSlidePause(!slidePause)}></div>
            ) : ('')
          }
      </div>
      {
        nav ? (
          <div className="imageSlide__nav">
            <div className="imageSlide__nav_prev" onClick={() => goPrev()}></div>
            <div className="imageSlide__nav_next" onClick={() => goNext()}></div>
          </div>
        ) : ''
      }
      {
        dots ? (
          <div className="imageSlide__dots">
            {
              data.map((item, i) => {
                return <span className={`imageSlide__dot ${index === i ? ' active' : ''}`} onClick={() => setIndex(i)} key={i}></span>
              })
            }
          </div>
        ) : ''
      }
    </div>
  )
}

export default Slide