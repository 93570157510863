import React, { useState } from 'react'
import ReactMarkdown from 'react-markdown'
import { Link } from 'react-router-dom'

import './style.min.css'
import data from './data.json'
import MeImg from '../../assets/img/me.jpg'

const About = () => {
  const [ storyIndex, setStoryIndex ] = useState(0);

  const moveIndex = () => {
    let newIndex = storyIndex+1 === data.length ? 0 : storyIndex+1;
    setStoryIndex(newIndex)
  }

  return (
    <article id="about">
      <section className="about_picture">
        <img src={MeImg} alt="It's me! Facu!" />
      </section>
      <section className="about_info">
        <ReactMarkdown>
          {data[storyIndex]}
        </ReactMarkdown>
        <p className="about_link_container">
          <Link to="/contact" className="about_link">
            <span>
              {data.length === storyIndex+1 ? 'Contact me' : 'Yes'}
            </span>
          </Link>
          {data.length !== storyIndex+1 ? <button className="about_link" onClick={() => moveIndex()}><span>No</span></button> : '' }
        </p>
      </section>
    </article>
  )
}

export default About