import { Fragment, useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import ReactMarkdown from 'react-markdown'

import Slide from '../../components/Slide'
import Error from '../../components/Error'

const WorkPage = ({ folder }) => {

  const [ state, setState ] = useState({
    isLoading: true,
    error: false,
    json: {}
  })

  useEffect(() => {

    const jsonFile = `/public/${folder}/data.json`;
    fetch(jsonFile)
      .then(res => res.json())
      .then(json => {
        setState({
          ...state,
          isLoading: false,
          json
        })
      })
      .catch(error => {
        setState({
          ...state,
          isLoading: false,
          error
        })
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getImages = limit => {
    let imgs = [];

    for (let x = 1; limit+1 > x; x++) {
      const z = x < 10 ? '0' + x : x;
      imgs[z-1] = { 'url': `/public/${folder}/img${z}.jpg` }
    }

    return imgs;
  }

  if (state.error) {
    return (
      <Error text="We had a problem finding the data. Please try gain."/>
    )
  }
  if(state.isLoading) {
    return (
      <div className="loading">
        Loading
        <span>.</span>
        <span>.</span>
        <span>.</span>
      </div>
    )
  }
  if (!!Object.keys(state.json).length) {
    const work = state.json,
          imgArr = getImages(work.imgs);

    return (
      <Fragment>
        <div className="work_view_images">
          <Slide data={imgArr} />
        </div>
        <div className="work_view_info">
          <h3 className="work_info_name">{work.name} <span>{work.year}</span></h3>
          <ReactMarkdown className="work_info_description">
            {work.info.description}
          </ReactMarkdown>
          <p className="work_info_tittle hl">Entrusted with</p>
          <ReactMarkdown className="work_info_entrusted">
            {work.info.entrusted}
          </ReactMarkdown>
          <p className="work_info_tittle">
            <span className="hl">Technical sheet</span><br/>
            Code technologies I got involved with while working on this Project.
          </p>
          <ReactMarkdown className="work_info_entrusted">
            {work.info.tech}
          </ReactMarkdown>
          {
            work.info.others ? (
              <Fragment>
                <p className="work_info_tittle">
                  <span className="hl">Others</span><br/>
                  Some additional information about the Project.
                </p>
                <ReactMarkdown linkTarget="_blank" className="work_info_entrusted">
                  {work.info.others}
                </ReactMarkdown>
              </Fragment>
            ) : ''
          }
          {
            work.company ? (
              <img src={`/${work.company}.svg`} alt={work.company} className="work_info_companyLogo" />
            ) : ''
          }
          <Link to="/works" className="work_info_back">
            <span className="back__arrow"></span>
            <span className="back__arrow"></span>
            <span className="back__arrow"></span>
            <span className="back__text">Go b<span className="back__text_animate">a</span>ck</span>
          </Link>
        </div>
      </Fragment>
    )
  }

}

export default WorkPage