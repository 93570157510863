import { Fragment, useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { Link } from 'react-router-dom'

import './style.min.css'
import Error from '../../components/Error'
import WorkBox from './workBox'
import WorkPage from './workPage'
import data from './data.json'

const Works = () => {

  const params = useParams();
  const url = window.location.pathname;

  const [ viewWork, setViewWork ] = useState(null);
  const [ btnPos, setBtnPos ] = useState(9);

  useEffect(() => {
    switch (true) {
      case window.innerWidth <= 686:
        setBtnPos(3)
        break;
      case window.innerWidth <= 1178:
        setBtnPos(5)
        break;
      case window.innerWidth <= 1480:
        setBtnPos(8)
        break;
      case window.innerWidth <= 1780:
        setBtnPos(7)
        break;
      default:
        setBtnPos(9)
    }
  }, [])

  useEffect(() => {
    if (!!Object.keys(params).length) {
      const work = data.find(work => work.id === parseInt(params.id));
      setViewWork(work);
    } else {
      setViewWork(null)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [url])

  if(!!viewWork){
    return (
      <article id="work_view">
        <WorkPage folder={viewWork.folder} />
      </article>
    )
  }

  if (data) {
    return (
      <article id="works">
        {
          data.slice(0).reverse().map((item, i) => {
            if (i === btnPos) {
              return <Fragment key={i}>
                <Link to="/about" className="work_item work_item_link">
                  <span>About<br/>me</span>
                </Link>
                <WorkBox data={item}/>
              </Fragment>
            }
            return <WorkBox data={item} key={i} />
          })
        }
      </article>
    )
  }

  return <Error text="We had a problem finding the data. Please try gain."/>
}

export default Works