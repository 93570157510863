import React, { useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';

function Form() {
  const siteKey = '6LeUkx8TAAAAAJt0aTTfuDGwWfAchO6n5BaOZNqG';
  //const siteKey = '6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI';
  const recaptchaRef = React.createRef();

  const initialState = {
    name: '',
    email: '',
    subject: '',
    message: '',
    error: '',
    captcha: false,
    isLoading: false,
    sended: false
  }
  const [ state, setState ] = useState(initialState)

  const handleChange = (input, e) => {
    setState({...state, [input]: e.target.value})
  }

  const captchaChangeHandler = (value) => {
    console.log(value)
    if (!!value) {
      setState({ ...state, captcha: true })
    } else {
      setState({ ...state, captcha: false })
    }
  }

  const handleSubmit = (e) => {
    e.preventDefault();

    if(!state.captcha) {
      return console.log('Error recaptcha');
    }

    const email = {
      "name": state.name,
      "email": state.email,
      "subject": state.subject,
      "message": state.message
    }

    setState({...state, isLoading: true})

    fetch('/api/email.php', {
      method: 'POST',
      mode: 'no-cors',
      body: JSON.stringify(email),
      credentials: 'same-origin',
      headers: { 'Content-Type': 'application/json; charset=UTF-8' }
    })
    .then(res => {
      if (res.ok && res.status === 200) {
        setState({
          ...initialState,
          sended: true
        })
      } else {
        setState({...state, isLoading: false})
        throw console.log('error: ', res)
      }
    })
    .catch(err => {
      setState({...state, error: err})
    })
  }

  if (state.sended) {
    return (
      <div className="form-sent">
        <b>It's away!</b> The message has been sended. I'll get back to you as soon as I can!<br/>
        <span onClick={() => setState({...state, sended: false})}>Okay!</span>
      </div>
    )
  }

  return (
    <form onSubmit={e => handleSubmit(e)} autoComplete="off">
      {
        state.isLoading ? (
          <div className="loading_container">
            <div className="loading">
              Loading
              <span>.</span>
              <span>.</span>
              <span>.</span>
            </div>
          </div>
        ) : ''
      }
      <div className="input_group">
        <label htmlFor="name">Name</label>
        <input type="text" name="name" id="name" required autoComplete="off"
        value={state.name} onChange={e => handleChange('name', e)}/>
      </div>
      <div className="input_group">
        <label htmlFor="email">Email</label>
        <input type="email" name="email" id="email" required autoComplete="off"
        value={state.email} onChange={e => handleChange('email', e)}/>
      </div>
      <div className="input_group">
        <label htmlFor="subject">Subject</label>
        <input type="text" name="subject" id="subject" required autoComplete="off"
        value={state.subject} onChange={e => handleChange('subject', e)} />
      </div>
      <div className="input_group">
        <label htmlFor="message">Message</label>
        <textarea name="message" id="message" cols="30" rows="10" required autoComplete="off"
        value={state.message} onChange={e => handleChange('message', e)}></textarea>
      </div>
      <div className="form_captcha">
        <ReCAPTCHA
          ref={recaptchaRef}
          sitekey={siteKey}
          onChange={captchaChangeHandler}
          theme="dark"
        />
      </div>
      <button className={`form_send${state.captcha ? ' available' : ''}`} type="submit">
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        Send
      </button>
    </form>
  )
}

export default Form;