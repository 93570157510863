import { useEffect } from 'react'
import { Link } from 'react-router-dom'
import './style.min.css'

const NF404 = () => {

  useEffect(() => {
    document.querySelector('.main_container').style.backgroundColor = '#01040c';
  }, [])

  return (
    <article id="not-found">
      <h1>
        <span data-text="404">404</span>
        <h3>Sorry!</h3>
      </h1>
      <h2 data-text="Sorry! Requested url doesn't exist">Requested url doesn't exist</h2>
      <section>
        <Link to={'works'}>looking for my works?</Link>
      </section>
    </article>
  )
}

export default NF404