import { Fragment, useState, useEffect } from 'react'
import { Outlet, Link, NavLink } from 'react-router-dom'

import { ReactComponent as Logo } from '../../assets/img/logo.svg'

import { ReactComponent as Home } from '../../assets/img/home.svg'
import { ReactComponent as Works } from '../../assets/img/work.svg'
import { ReactComponent as About } from '../../assets/img/me.svg'
import { ReactComponent as Contact } from '../../assets/img/mail.svg'
import { ReactComponent as Blog } from '../../assets/img/blog.svg'

import { ReactComponent as Facebook } from '../../assets/img/facebook-24px.svg'
import { ReactComponent as Upwork } from '../../assets/img/upwork-24px.svg'
import { ReactComponent as Linkedin } from '../../assets/img/linkedin-24px.svg'

import '../../components/Bg'

const Nav = () => {

  const [ mobileSocial, setMobileSocial ] = useState(false)

  useEffect(() => {
    console.log(
      '%cWellcome to',
      'font-family:"Monthserrat",sans-serif;'
    );
    console.log(
      '%cWebCreatio.net %cby Facundo Barril - 2023',
      'font-family:"Monthserrat",sans-serif;color:#FBBC21;font-size:2em;',
      'font-family:"Monthserrat",sans-serif;'
    );
    console.log(
      `%cDon't run any type of text here if you don't know what you're doing`,
      'font-family:"Monthserrat",sans-serif;background-color:#21c8fbb0;color:#fff;border:1px solid #23c8fb;padding:2px 6px;'
    );
  }, [])

  const toggleMobileSocial = () => {
    setMobileSocial(!mobileSocial)
  }

  return (
    <Fragment>
      <div id="bg"></div>
      <section className="main_nav">
        <Link className="logo_container" to="/">
          <Logo className="logo" />
        </Link>
        <nav>
          <NavLink className={`nav_link`} to="/" >
            <Home id="home_nav_icon" className="nav_icon" />
            <span className="nav_label">Home</span>
          </NavLink>
          <NavLink className={`nav_link`} to="/works" >
            <Works id="works_nav_icon" className="nav_icon" />
            <span className="nav_label">Works</span>
          </NavLink>
          <NavLink className={`nav_link`} to="/about" >
            <About id="about_nav_icon" className="nav_icon" />
            <span className="nav_label">About</span>
          </NavLink>
          <NavLink className={`nav_link`} to="/contact" >
            <Contact id="contact_nav_icon" className="nav_icon" />
            <span className="nav_label">Contact</span>
          </NavLink>
          <span className="nav_link">
            <Blog id="social_nav_icon" className={`nav_icon${ mobileSocial ? ' active' : ''}`} onClick={() => toggleMobileSocial()}/>
          </span>
        </nav>
        <div className={`nav_socials${mobileSocial ? ' show_mobile' : ' hide_mobile'}`}>
          <a
            href="https://www.facebook.com/facu.barril"
            className="socials_link"
            target="_blank"
            rel="noreferrer"
          >
            <Facebook className="socials_icon"/>
            <span className="socials_label">Facebook</span>
          </a>
          <a
            href="https://www.upwork.com/o/profiles/users/_~015d7d843431ad2cea/"
            className="socials_link"
            target="_blank"
            rel="noreferrer"
          >
            <Upwork className="socials_icon"/>
            <span className="socials_label">Upwork</span>
          </a>
          <a
            href="https://www.linkedin.com/pub/facundo-barril/41/b31/621"
            className="socials_link"
            target="_blank"
            rel="noreferrer"
          >
            <Linkedin className="socials_icon"/>
            <span className="socials_label">Linkedin</span>
          </a>
        </div>
      </section>
      <section className="main_container">
        <Outlet/>
      </section>
      <footer>
        &copy; WebCreatio v.20240912
      </footer>
    </Fragment>
  )
}

export default Nav