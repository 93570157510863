import { useEffect } from 'react'
import { Link } from 'react-router-dom'

import './style.min.css';

import Me from '../../assets/img/home.jpg'

const Home = () => {

  useEffect(() => {
    let links = document.querySelectorAll('.action_link');

    if (!!links.length) {
      links.forEach(item => {
        if (!item.classList.contains('ctd')) {
          let compute = item.innerText.split(' '),
          split = compute.join('& &').split('&');

          item.innerHTML = split.map((el, i) => {
            let text = '';

            el.split('').forEach(l => {
              text += `<span>${l}</span>`
            })

            return text;
          }).join('');

          item.classList.add('ctd');
        }
      })
    }
  }, [])

  return (
    <article id="home">
      <div className="home_image">
        <img src={Me} alt="It's me!" />
      </div>
      <h2 className="welcome">
        Hi! I'm <span className="hl ">Facundo</span>,<br/>
        a Graphic Designer<br/>
        and Web Developer.<br/>
        <Link to="works" className="action_link">Check my work</Link>
      </h2>
    </article>
  )
}

export default Home