import { Link } from 'react-router-dom'

const WorkBox = ({ data }) => {

  let workUrl = data.name.includes(' ') ? data.name.replace(' ', '_') : data.name;

  return (

    <Link to={`/works/${data.id}/${workUrl.toLowerCase()}`} className="work_item">
      <img src={`/public/${data.folder}/cover.jpg`} alt={data.name} className="work_cover" loading='lazy' />
      <div className="work_data">
        <span className="work_name">{data.name}</span>
        <span className="work_year">{data.year}</span>
        { !!data.company ? (
          <span className="work_company">
            <img src={`/${data.company}.svg`} alt={data.company} className="work_company_image" />
          </span>
        ) : ('') }
      </div>
    </Link>

  )
}

export default WorkBox