import { Route, createBrowserRouter, createRoutesFromElements, RouterProvider } from 'react-router-dom';

import Nav from './routes/Nav';
import Home from './routes/Home';
import Works from './routes/Works';
import About from './routes/About';
import Contact from './routes/Contact';
import NF404 from './routes/404';

import './css/App.min.css';

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path='/' element={ <Nav /> }>
      <Route index element={ <Home /> }/>
      <Route path='works' element={ <Works /> }>
        <Route path=':id/:name' element={ <Works /> }/>
      </Route>
      <Route path='about' element={ <About /> }/>
      <Route path='contact' element={ <Contact /> }/>
      <Route path='*' element={ <NF404 /> }/>
    </Route>
  )
)

const App = () => <RouterProvider router={router} />

export default App;
