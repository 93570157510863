import React from 'react'
import './Error.min.css'

const Error = ({text}) => {

  const report = () => {

    const report = {
      "path": window.location.pathname,
      "screen": `${window.screen.width}x${window.screen.height}`,
      "navigator": {
        "agent": window.navigator.userAgent,
        "brand": window.navigator.userAgentData.brands[window.navigator.userAgentData.brands.length-1].brand,
        "lang": window.navigator.language,
        "size": `${window.innerWidth}x${window.innerHeight}`
      }
    }

    fetch('/api/report.php', {
      method: "POST",
      body: JSON.stringify(report),
      headers: { "Content-Type": "application/json; charset=UTF-8" }
    })
    .then(res => {
      if (res.ok && res.status === 200) {
        document.querySelector('.error span').classList.add('ac');
        document.querySelector('.error span').innerText = 'Thank you.'
      }
    })
    .catch(err => {
      document.querySelector('.error span').classList.add('ac');
      document.querySelector('.error span').innerText = 'Something went wrong. Try using the contact form.'
      console.log('err: ', err)
    })

  }

  return (
    <div className="error">
      <b>Woops!</b> {!!text ? text : 'We encounter a problem. Please try gain.'}<br/>
      <span>If this continues please click <button onClick={() => report()}>here</button> to report the issue.</span>
    </div>
  )
}

export default Error