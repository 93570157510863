import './styles.min.css'
import Form from './form'

const Contact = () => {

  return (
    <article id="contact">
      <div className="form_container">
        <p>For questions, work inquiries, or just to get in touch, fill out the form below and I'll get back to you as soon as I can!</p>
        <Form/>
      </div>
    </article>
  )
}

export default Contact